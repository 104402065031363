import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import ProjectAvailableUnits from "~/components/organisms/ProjectAvailableUnits";
import Section from "~/components/templates/SectionProject";
import { IProject } from "~/entities/project";

export interface SectionProjectAvailableUnitsProps {
  className?: string;
  id: string;
  title: string;
  description: string;
  project: IProject;
}

const SectionProjectAvailableUnits: React.FC<
  SectionProjectAvailableUnitsProps
> = ({ className = "", id, title, description, project }) => {
  const { t } = useTranslation();
  const [percentSold, setPercentSold] = useState<string>("0");

  useEffect(() => {
    function calculatePercentage(project: IProject): string {
      if (
        !project ||
        typeof project.availableUnits !== "number" ||
        typeof project.totalUnits !== "number" ||
        project.totalUnits === 0
      ) {
        throw new Error("Invalid project data");
      }

      const percent =
        ((project.totalUnits - project.availableUnits) / project.totalUnits) *
        100;
      const formattedPercent =
        percent % 1 === 0 ? percent.toFixed(0) : percent.toFixed(2);
      return `${formattedPercent}%`;
    }

    if (project && project.totalUnits) {
      const percent = calculatePercentage(project);
      setPercentSold(percent);
    }
  }, [project]);

  if (!project) return null;

  const projectAvaiableUnits = project.unitTypes.filter(
    (item) => !!item.availableUnits
  );

  return (
    <Section id={id} className={className}>
      <ProjectAvailableUnits
        availableUnits={projectAvaiableUnits}
        title={t(title)}
        description={t(description, {
          soldNumber: project.totalUnits - project.availableUnits,
          percent: percentSold,
        })}
      />
    </Section>
  );
};

export default SectionProjectAvailableUnits;
